<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <!-- BOC:[error] -->
        <ApiErrorDialog v-if="api.isError" :api="api" />
        <ApiErrorDialog v-if="uploadApi.isError" :api="uploadApi" />
        <!-- EOC -->
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("title.studio") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div>
            <div class="shop-panel">
              <v-img
                :src="require('@/assets/explore/menu/gallery.png')"
                max-width="80"
                class="ms-2 d-flex justify-center align-end text-center text-stroke-md"
                style="cursor: pointer"
                @click="gallery()"
              >
              {{ $t("title.gallery") }}
              </v-img>
            </div>
            <div class="medal-wrapper">
              <div @click="art()" style="cursor: pointer" class="medal-panel d-flex justify-end align-center text-h6">
                {{ awards }}
                <v-img
                  :src="require('@/assets/explore/goldenbrush.png')"
                  max-width="30"
                  class="ms-2"
                >
                </v-img>
              </div>
            </div>
            <v-img
              class="d-flex align-center mx-auto"
              :src="require('@/assets/explore/studio.png')"
              contain
            >
            <div
                  v-if="uploadApi.isLoading || removeApi.isLoading"
                  class="text-center ma-5"
                  style="right: 24%; top: 55px; position: absolute"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              <div style="right: 17%; top: 55px; position: absolute">
               
                <div
                  style="position: absolute; z-index: 9;"
                  v-if="drawing && drawing.GalleryAward && !api.isLoading &&
                    !uploadApi.isLoading &&
                    !removeApi.isLoading"
                >
                <span class="d-flex">
                <span v-for="i in drawing.awardCount" :key="i">
                  <v-img
                    class="d-flex justify-center align-end"
                    :class="'mr-'+(i)"
                    contain
                    max-width="30"
                    :src="drawing.GalleryAward.imageUrl"
                  ></v-img>
                </span>
              </span>
                </div>
                <v-img
                  v-if="
                    !api.isLoading &&
                    !uploadApi.isLoading &&
                    !removeApi.isLoading
                  "
                  :src="imageUrl ? imageUrl : currentImageUrl"
                  contain
                  :style="currentImageUrl || imageUrl ?'cursor: pointer;':''"
                  style="
                    width: 28vw;
                    height: 28vw;
                    max-height: 134px;
                    max-width: 134px;
                  "
                  @click="currentImageUrl || imageUrl ? viewImage() :null"
                >
                </v-img>
              </div>

              <div class="d-flex align-center mx-auto">
                <AbstractAvatar
                  :avatar="studentAvatar"
                  :height="180"
                  :width="180"
                  class="ms-8"
                ></AbstractAvatar>
              </div>
            </v-img>

            <div
              class="text-left py-1 d-flex justify-space-around align-center"
              style="height: 50px; margin-top: -70px"
            >
              <div>
                <v-img
                  :src="require('@/assets/explore/bin.png')"
                  :class="
                    imageUrl || currentImageUrl ? null : 'disabled-item-image'
                  "
                  max-width="55"
                  class="ms-2 d-flex justify-center align-end text-center text-stroke-black-sm"
                  :style="imageUrl || currentImageUrl ? 'cursor: pointer' : ''"
                  style="overflow: visible"
                  @click="openDialog"
                >
                {{$t("action.remove")}}
                </v-img>
              </div>
              <div>
                <v-img
                  :src="require('@/assets/explore/paint.png')"
                  max-width="90"
                  :class="
                    (!theme || api.isLoading) ? 'disabled-item-image' : null
                  "
                  class="ms-2 d-flex justify-center align-end text-center text-stroke-black-sm"
                  style="cursor: pointer"
                  @click="openFileInput"
                >
                {{$t("action.draw")}}
                </v-img>
                <div class="text-caption my-1">{{ $t("string.upload_art") }}</div>
              </div>

              <!-- <v-btn
              v-if="showImage"
              fab
                dark
                color="red"
                class="text-h6 px-4 text-stroke-md"
                @click="openDialog"
              >
              <v-icon>mdi mdi-delete</v-icon>
              </v-btn> -->
            </div>

            <div style="height: 50px; margin-top: -50px; margin-bottom: 30px">
              <v-file-input
                style="display: none"
                v-model="image"
                ref="fileInput"
                accept="image/*"
              ></v-file-input>
              <!-- <div class="pa-2 text-center">
               
                <v-btn
              fab
                color="yellow"
                class="text-h6 px-4 "
                @click="openFileInput"
              >
              <v-icon>mdi mdi-brush</v-icon>
              </v-btn>
                <div class="font-weight-black pt-1">Upload your art!</div>
              </div> -->
            </div>

            <div v-if="theme && api.isLoading != true" class="items-panel pa-3 mt-12">
              <div class="text-stroke-md text-center text-h5">
                {{ $t("string.month_theme") }}
              </div>
              <div class="text-stroke-black-md text-center text-h4">
                {{ theme.name }}
              </div>
              <div class="pt-3">
                <v-img
                  :src="theme.imageUrl"
                  contain
                  max-height="200"
                  max-width="300"
                  class="mx-auto"
                ></v-img>
              </div>
            </div>
          </div>

          <v-card class="save-confirm" v-if="isView">
            <div class="view-image mx-auto">
              <div class="text-right pa-3">
                <CloseBtn size="40" :callback="closeView" />
              </div>
              <div class="d-flex align-center">
                <div style="max-height: 70vh; width: 100%">
                  <v-img
                    :src="imageUrl ? imageUrl : currentImageUrl"
                    contain
                    class="mx-auto"
                    style="max-height: 70vh; width: 100%"
                  >
                  </v-img>
                  <div
                    style="
                      z-index: 1 !important;
                      background-color: white;
                      max-width: 250px;
                    "
                    class="mx-auto mt-3 d-flex"
                  >
                  <div
                      class="d-flex justify-center pa-1"
                      v-if="drawing.awardCount"
                    >
                      <div
                        v-for="i in drawing.awardCount"
                        :key="i"
                      >
                        <v-img
                          contain
                          max-width="30"
                          v-if="
                            drawing.GalleryAward &&
                            drawing.GalleryAward.imageUrl
                          "
                          :src="drawing.GalleryAward.imageUrl"
                        ></v-img>
                      </div>
                    </div>
                    <div class="mx-auto">
                      <div class="font-weight-black text-center pt-2">
                        {{ transformName(auth.User.name) }}
                    </div>
                    <div
                      v-if="auth.Institution"
                      class="text-subtitle-2 text-center text--secondary"
                    >
                      {{ auth.Institution.name }}<br />{{ auth.Classroom.name }}
                    </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-container>
        <v-dialog v-model="confirmDialog" max-width="300" class="rounded-lg">
          <v-card width="" elevation="1" outlined class="" rounded="lg">
            <div class="d-flex justify-content-between">
              <v-spacer></v-spacer>
              <CloseBtn
                size="30"
                :callback="
                  () => {
                    confirmDialog = false;
                  }
                "
              />
            </div>
            <v-card-text class="mb-3">
              <div class="text-center red lighten-4 rounded-lg py-3 px-3">
                <div class="text-h6">{{ $t("string.are_you_sure") }}</div>
              </div>
              <div class="text-center pt-2">
                <v-btn
                  :loading="removeApi.isLoading"
                  dark
                  color="red"
                  class="text-h6 px-4"
                  @click="remove"
                >
                {{$t("action.remove")}}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
  
  <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
import axios from "axios";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    coin: (state) => state.coin.data,
    tutorial: (state) => state.tutorial.data,
  }),
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),

    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  data: () => ({
    confirmDialog: false,
    image: null,
    imageUrl: null,
    showImage: true,
    isView: false,
    currentImageUrl: null,
    theme: null,
    drawing: {},
    awards: 0,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    uploadApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    removeApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    coinsApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    avatar: {},
    authData: [],
  }),
  created() {
    this.avatar = this.$_.cloneDeep(JSON.parse(this.auth.Player.avatar));
    this.studentAvatar = this.$_.cloneDeep(this.auth.Player.avatar);
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/studio/visit";
    this.uploadApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/studio/draw";
    this.removeApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/studio/remove";
      this.coinsApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/inventory";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.uploadApi.callbackReset = () => {
      this.uploadApi.isLoading = true;
      this.uploadApi.isError = false;
      this.uploadApi.error = null;
    };
    this.removeApi.callbackReset = () => {
      this.removeApi.isLoading = true;
      this.removeApi.isError = false;
      this.removeApi.error = null;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.uploadApi.callbackError = (e) => {
      this.uploadApi.isLoading = false;
      this.uploadApi.isError = true;
      this.uploadApi.error = e;
    };

    this.removeApi.callbackError = (e) => {
      this.removeApi.isLoading = false;
      this.removeApi.isError = true;
      this.removeApi.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.theme = resp.Theme;
      //this.awards = resp.Award;
      this.drawing = resp.WorkshopDrawing;
      this.currentImageUrl =
        resp.WorkshopDrawing && resp.WorkshopDrawing.imageUrl
          ? resp.WorkshopDrawing.imageUrl
          : null;
      this.api.isLoading = false;
    };
    this.uploadApi.callbackSuccess = (resp) => {
      this.drawing = resp.WorkshopDrawing;

      this.uploadApi.isLoading = false;
      this.$api.fetch(this.api);
    };
    this.removeApi.callbackSuccess = (resp) => {
      this.theme = resp.Theme;
      this.drawing = resp.WorkshopDrawing;
      //this.awards = resp.Award;
      this.currentImageUrl = null;
      this.imageUrl = null;

      this.confirmDialog = false;
      this.removeApi.isLoading = false;
    };
    this.coinsApi.callbackReset = () => {
      this.coinsApi.isLoading = true;
      this.coinsApi.isError = false;
      this.coinsApi.error = null;
    };
    this.coinsApi.callbackError = (e) => {
      this.coinsApi.isLoading = false;
      this.coinsApi.isError = true;
      this.coinsApi.error = e;
    };
    this.coinsApi.callbackSuccess = (resp) => {
      this.coinsApi.isLoading = false;
      var coins = null;
      coins = resp.filter(function (entry) {
        return entry.Item.key == "goldenbrush";
      });
      if (coins[0]) {
        this.awards = coins[0].Inventory.quantity;
      } else {
        this.awards = 0;
      }
      
    };
  },
  watch: {
    image: function (val) {
      this.image = val;
      if (val) {
        this.showImage = true;
        this.reduceFileSize(val);
      } else {
        this.imageUrl = null;
      }
    },
  },
  mounted() {
    this.$api.fetch(this.api);
    this.$api.fetch(this.coinsApi);
  },
  methods: {
    viewImage() {
      if (!this.confirmDialog) {
        this.isView = true;
      }
    },
    reduceFileSize(val) {
      const file = val;
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxWidth = 800;
          const maxHeight = 600;

          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          const resizedDataURL = canvas.toDataURL("image/jpeg", 0.7);

          const byteString = atob(resizedDataURL.split(",")[1]);
          const mimeString = resizedDataURL
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const uint8Array = new Uint8Array(arrayBuffer);

          for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
          }

          const blob = new Blob([arrayBuffer], { type: mimeString });

          // Convert Blob to File
          const fileName = "resized_image.jpg"; // Change the filename as needed
          const file = this.blobToFile(blob, fileName);

          // Call the upload function with the File object
          this.upload(file);
        };

        img.src = event.target.result;
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    blobToFile(blob, fileName) {
      const fileType = blob.type;
      const file = new File([blob], fileName, { type: fileType });
      return file;
    },
    upload(blob) {
      this.uploadApi.isLoading = true;
      const formData = new FormData();
      const token = "Bearer " + this.auth.Session.token;
      const config = {
        headers: { Authorization: token },
      };
      formData.append("art", blob);
      formData.append("themeKey", this.theme.key);
      axios
        .post(
          this.$api.servers.game + "/api/v1/en/game/studio/draw",
          formData,
          config
        )
        .then((response) => {
          this.imageUrl = response.data.WorkshopDrawing.imageUrl;
          this.drawing = response.data.WorkshopDrawing;
          this.uploadApi.isLoading = false;
        });
      // this.api.params = this.$_.clone(formData);
      // this.$api.fetch(this.api);
    },

    closeView() {
      this.isView = false;
    },
    remove() {
      this.$api.fetch(this.removeApi);
    },
    openDialog() {
      if (this.imageUrl || this.currentImageUrl) {
        this.confirmDialog = true;
      }
    },
    gallery() {
      this.$router.push({
        name: "PageHomeGallery",
      });
    },
    art() {
      this.$router.push({
        name: "PageHomeArt",
      });
    },
    openFileInput() {
      if(!this.theme) return;
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      // Handle the uploaded file (e.g., display preview, upload to server, etc.)
      console.log("Uploaded file:", file);
    },
    transformName(name) {
    const words = name.split(' ');
    const firstName = words[0];
    const initials = words.slice(1).map(word => word.charAt(0) + '.');
    const transformedName = [firstName, ...initials].join(' ');
    
    return transformedName;
},
    exit() {
      this.$router.push({
        name: "PageExploreMenu",
      });
    },
  },
};
</script>
  
  <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.medal-wrapper {
  display: flex;
  justify-content: flex-end;
  /* position: fixed; */
  max-width: 480px !important;
  width: 100%;
  z-index: 1;
}

.view-image {
  max-width: 480px;
  min-height: 100%;
  /* background-color: #ffb8b8; */
  position: relative;
}
.medal-panel {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  z-index: 1;
  min-width: 120px;
  margin: 10px;
  right: 0px;
  padding: 2px 10px; /* remove this */
  background-color: #fff;
  text-align: right;
}

.text-border {
  text-shadow: -1px 1px 0 rgb(255, 255, 255), 1px 1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255);
  z-index: 3;
}
.item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 10px;
  margin-top: 10px;
}

.low-item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px;
  margin-top: 10px;
}
.avatar {
  height: 140px;
  overflow: hidden;
}
.crop {
  width: 70px;
  height: 30px;
  overflow: hidden;
}

.hat {
  width: 100px;
  height: 100px;
  margin: -10px 0 0 -10px;
}
.avatar-hat {
  position: absolute;
}

.disabled-item-image {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  pointer-events: none;
  opacity: 0.7;
}

@media (max-height: 500px) {
  #shop {
    background-size: auto 500px !important;
  }
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-md {
  color: #fff;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.items-panel {
  border: #caad87 5px solid;
  background-color: #f7efe3;
  padding-bottom: 30px !important;
}
.shop-panel {
  z-index: 1;
  margin: 10px;
  left: 0px;
  position: absolute;
}
.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}


.text-stroke-black-md {
  color: #fff;
  text-shadow: #000000 3px 0px 0px, #000000 2.83487px 0.981584px 0px,
    #000000 2.35766px 1.85511px 0px, #000000 1.62091px 2.52441px 0px,
    #000000 0.705713px 2.91581px 0px, #000000 -0.287171px 2.98622px 0px,
    #000000 -1.24844px 2.72789px 0px, #000000 -2.07227px 2.16926px 0px,
    #000000 -2.66798px 1.37182px 0px, #000000 -2.96998px 0.42336px 0px,
    #000000 -2.94502px -0.571704px 0px, #000000 -2.59586px -1.50383px 0px,
    #000000 -1.96093px -2.27041px 0px, #000000 -1.11013px -2.78704px 0px,
    #000000 -0.137119px -2.99686px 0px, #000000 0.850987px -2.87677px 0px,
    #000000 1.74541px -2.43999px 0px, #000000 2.44769px -1.73459px 0px,
    #000000 2.88051px -0.838247px 0px;
}

.text-stroke-black-sm {
  color: #fff;
  text-shadow: #000000 3px 0px 0px, #000000 2.83487px 0.981584px 0px,
    #000000 2.35766px 1.85511px 0px, #000000 1.62091px 2.52441px 0px,
    #000000 0.705713px 2.91581px 0px, #000000 -0.287171px 2.98622px 0px,
    #000000 -1.24844px 2.72789px 0px, #000000 -2.07227px 2.16926px 0px,
    #000000 -2.66798px 1.37182px 0px, #000000 -2.96998px 0.42336px 0px,
    #000000 -2.94502px -0.571704px 0px, #000000 -2.59586px -1.50383px 0px,
    #000000 -1.96093px -2.27041px 0px, #000000 -1.11013px -2.78704px 0px,
    #000000 -0.137119px -2.99686px 0px, #000000 0.850987px -2.87677px 0px,
    #000000 1.74541px -2.43999px 0px, #000000 2.44769px -1.73459px 0px,
    #000000 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 60px 0 0px 0;
  /* background-color: #ffb8b8; */
  position: relative;
}

.main-body {
  /* background-color: #ffb8b8; */
  background-color: rgba(255, 71, 123, 0.3);
}

#shop {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.buy-confirm {
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.selected-chest {
  background-color: #8b572977;
  border-radius: 30px;
}

#shop-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.shop-plot {
  background: rgba(0, 0, 0, 0.2);
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 15px;
  border-radius: 10px;
}

.welcome-enter-active {
  animation: welcome-in 0.5s;
}
.welcome-leave-active {
  animation: welcome-out 0.5s reverse;
}
@keyframes welcome-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes welcome-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.low_graphics {
  background: none !important;
}

.low_island_plot {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px;
  /* padding: 20px !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_title_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  padding: 5px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin: 20px;
}
.low_island_building_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_coin_plot {
  background-color: rgb(246, 246, 246) !important;
  width: 100%;
  margin-right: 10px;
  padding: 5px 2%;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.low_plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}
.plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.save-confirm {
  position: fixed;
  background-color: #ffb8b8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}
</style>